import React, { useRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const calculateVPD = (airTemp, rh, tempDiff) => {
  const leafTemp = airTemp + tempDiff;
  const E_sat_leaf =
    (610.78 * Math.exp((leafTemp / (leafTemp + 237.3)) * 17.2694)) / 1000;
  const E_sat_air =
    (610.78 * Math.exp((airTemp / (airTemp + 237.3)) * 17.2694)) / 1000;
  const E_actual = E_sat_air * (rh / 100);
  return E_sat_leaf - E_actual;
};

const colorVPD = (val) => {
  if (val <= 0) return "#858585";
  if (val <= 0.4) return "purple";
  if (val <= 0.8) return "#6970ff";
  if (val <= 1.2) return "green";
  if (val <= 1.6) return "rgb(212, 212, 25)";
  return "#f44336";
};

const VPDChart = ({ tempDiff, highlightAirTemp, highlightHumi, setHighlightAirTemp, setHighlightHumi, updateLeafTemp, isMobile }) => {
  const chartRef = useRef(null);
  const [chartSize, setChartSize] = useState(400); // Default size

  useEffect(() => {
    const updateSize = () => {
      if (chartRef.current) {
        const containerWidth = chartRef.current.parentElement.offsetWidth;
        setChartSize(containerWidth);
      }
    };

    updateSize(); // Initial call to set the size
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [chartRef.current]);

  const squareSize = chartSize / 101;
  const width = chartSize;
  const height = chartSize;

  const humidities = Array.from({ length: 101 }, (_, i) => 100 - i);
  const airTemps = Array.from({ length: 101 }, (_, i) => i * 0.5 + 0.5);

  const highlightX = 100 - highlightHumi;
  const highlightY = Math.round((highlightAirTemp - 0.5) * 2);

  const highlightXPos = highlightX * squareSize;
  const highlightYPos = highlightY * squareSize;

  const handleChartClick = (e) => {
    const rect = chartRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const clickedHumi = 100 - Math.floor(x / squareSize);
    const clickedTemp = Math.round(y / squareSize) * 0.5 + 0.5;

    setHighlightHumi(clickedHumi);
    setHighlightAirTemp(clickedTemp);
    updateLeafTemp(clickedTemp);
  };

  const squares = [];
  const lines = [];

  airTemps.forEach((temp, tempIndex) => {
    humidities.forEach((rh, rhIndex) => {
      const vpd = calculateVPD(temp, rh, tempDiff);
      const fillColor = colorVPD(vpd);

      const x = rhIndex * squareSize;
      const y = tempIndex * squareSize;

      squares.push(
        <rect
          key={`${tempIndex}-${rhIndex}`}
          x={x}
          y={y}
          width={squareSize}
          height={squareSize}
          fill={tempIndex === highlightY && rhIndex === highlightX ? "white" : fillColor}
          stroke={
            tempIndex === highlightY && rhIndex === highlightX
              ? "#fff"
              : "rgba(174, 174, 174, 0.6)"
          }
          strokeWidth={tempIndex === highlightY && rhIndex === highlightX ? 1 : 0.5}
        />
      );
    });
  });

  lines.push(
    <line
      key="highlight-line-horizontal"
      x1={0}
      y1={highlightYPos + squareSize / 2}
      x2={width}
      y2={highlightYPos + squareSize / 2}
      stroke="#fff"
      strokeWidth="2.2"
      strokeOpacity="1"
      strokeDasharray="10,10"
    />
  );

  lines.push(
    <line
      key="highlight-line-vertical"
      x1={highlightXPos + squareSize / 2}
      y1={0}
      x2={highlightXPos + squareSize / 2}
      y2={height}
      stroke="#fff"
      strokeWidth="2.2"
      strokeOpacity="1"
      strokeDasharray="10,10"
    />
  );

  return (
    <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '100%' }}>
      <svg
        ref={chartRef}
        width={width}
        height={height}
        style={{ border: "1px solid black", position: 'absolute', top: 0, left: 0 }}
      >
        {squares}
        {lines}
      </svg>
      <svg width={width} height={height} style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, pointerEvents: 'none' }}>
        {/* Scale Labels for Humidity */}
        {Array.from({ length: 10 }, (_, i) => 100 - i * 10).map((val, i) => (
          <text
            key={`humi-${val}`}
            x={(i + 1) * (width / 10.5) - (isMobile ? 15 : 50)}
            y={10 + (isMobile ? 5 : 20)}
            fill="#fff"
            fontSize={isMobile ? "10px" : "14px"}
            fontFamily="Arial"
            textAnchor="middle"
          >
            {val}%
          </text>
        ))}

        {/* Scale Labels for Temperature */}
        {Array.from({ length: 10 }, (_, i) => 50 - i * 5).map((val, i) => (
          <text
            key={`temp-${val}`}
            x={20}
            y={height - ((50.5 - val) * 2) * squareSize + (isMobile ? i * 0 : 0)}
            fill="#fff"
            fontSize={isMobile ? "10px" : "14px"}
            fontFamily="Arial"
            textAnchor="middle"
          >
            {val} ºC
          </text>
        ))}

        <text
          x={width - 10}
          y={isMobile ? 40 : 60}
          fill="#fff"
          fontSize={isMobile ? "13px" : "18px"}
          fontWeight={'bold'}
          fontFamily="Arial"
          textAnchor="end"
        >
          ← 상대 습도
        </text>

        <text
          x={100}
          y={30}
          fill="#fff"
          fontSize={isMobile ? "13px" : "18px"}
          fontWeight={'bold'}
          fontFamily="Arial"
          textAnchor="left"
        >
          <tspan x="50" dy="1.2em">대</tspan>
          <tspan x="50" dy="1.2em">기</tspan>
          <tspan x="50" dy="1.2em">온</tspan>
          <tspan x="50" dy="1.2em">도</tspan>
          <tspan x="50" dy="1.2em">↓</tspan>
        </text>
      </svg>
      <svg
        width={width}
        height={height}
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}
        onClick={handleChartClick}
      >
        <rect width={width} height={height} fill="transparent" />
      </svg>
    </div>
  );
};

function App() {
  const [airTemp, setAirTemp] = useState(28);
  const [leafTemp, setLeafTemp] = useState(25);
  const [humi, setHumi] = useState(70);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const tempDiff = leafTemp - airTemp;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateLeafTemp = (newAirTemp) => {
    const newLeafTemp = newAirTemp + tempDiff;
    setLeafTemp(newLeafTemp);
  };

  const vpdValue = calculateVPD(airTemp, humi, tempDiff);

  const incrementAirTemp = () => {
    setAirTemp(prev => {
      const newAirTemp = Math.min(prev + 0.5, 50);
      updateLeafTemp(newAirTemp);
      return newAirTemp;
    });
  };

  const decrementAirTemp = () => {
    setAirTemp(prev => {
      const newAirTemp = Math.max(prev - 0.5, 0.5);
      updateLeafTemp(newAirTemp);
      return newAirTemp;
    });
  };

  const incrementLeafTemp = () => setLeafTemp(prev => Math.min(prev + 0.5, 50));
  const decrementLeafTemp = () => setLeafTemp(prev => Math.max(prev - 0.5, 0.5));

  const incrementHumi = () => setHumi(prev => Math.min(prev + 1, 100));
  const decrementHumi = () => setHumi(prev => Math.max(prev - 1, 0));

  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-${isMobile ? 12 : 8} d-flex flex-column align-items-center`} style={{padding: isMobile ? 0 : '' }}>
          <VPDChart
            tempDiff={tempDiff}
            highlightAirTemp={airTemp}
            highlightHumi={humi}
            setHighlightAirTemp={setAirTemp}
            setHighlightHumi={setHumi}
            updateLeafTemp={updateLeafTemp}
            isMobile={isMobile}
          />
          <div className={`mt-4 ${isMobile ? '' : 'text-below-chart'}`} style={{
            position: isMobile ? 'relative' : 'absolute',
            left: 0,
            width: '100%',
            maxWidth: '600px',
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#000'
          }}>
            <table className="table table-striped table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>습도의 영향 </th>
                  <th>VPD 범위</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span style={{color:'#a5a5a5'}}>■</span> 과습 위험 범위</td>
                  <td>0~0.4 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'purple'}}>■</span> 삽목, 정식직후</td>
                  <td>0.4~0.8 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'#6970ff'}}>■</span> 생육초기에 적합</td>
                  <td>0.8~1.0 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'green'}}>■</span> 생육중기에 적합</td>
                  <td>1.0~1.2 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'rgb(212, 212, 25)'}}>■</span> 생육후기에 적합</td>
                  <td>1.2~1.6 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'rgb(133, 133, 133)'}}>■</span> 건조 위험 범위</td>
                  <td>1.6~2.0 kPa</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={`col-md-${isMobile ? 12 : 4} d-flex flex-column align-items-center`} style={{padding:0}}>
          
          <div style={{ marginBottom: "20px", width: '100%', display:'flex',flexDirection:'column', flexWrap:'wrap',alignContent:'center' }}>
          <div className="d-flex align-items-center mb-2" style={{justifyContent:'flex-end'}}>
              <label className="form-label" style={{fontSize:isMobile ? 15 : 18}}>상대 습도 (%) = </label>
              
            <div style={{width:7}}></div>
              <input
              type="number"
              value={humi}
              onChange={(e) => setHumi(parseInt(e.target.value))}
              min="0"
              max="100"
              className="form-control text-center"
              style={{width:  isMobile ? 70:100, fontWeight: 'bold', fontSize:isMobile ? 15 : 18}}
            />
            
            <div style={{width:10}}></div>
              <div>
                <button className="btn btn-secondary" onClick={decrementHumi} style={{ margin: 0, width: isMobile ? 36.5 : 38, height: isMobile ? 36.5 : 38, marginRight: 5 }}><FontAwesomeIcon icon={faMinus} /></button>
                <button className="btn btn-secondary" onClick={incrementHumi} style={{ margin: 0, width: isMobile ? 36.5 : 38, height: isMobile ? 36.5 : 38, marginRight: 5 }}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
            </div>
            
            <div className="d-flex align-items-center mb-2"  style={{justifyContent:'flex-end'}}>
              <label className="form-label" style={{fontSize:isMobile ? 15 : 18}}>대기 온도 (°C) = </label>
              <div style={{width:7}}></div>
            <input
              type="number"
              value={airTemp}
              onChange={(e) => setAirTemp(parseFloat(e.target.value))}
              min="0.5"
              max="50"
              step="0.5"
              className="form-control text-center"
              style={{width:  isMobile ? 70:100, fontWeight: 'bold', fontSize:isMobile ? 15 : 18}}
            />
            <div style={{width:10}}></div>
              <div>
                <button className="btn btn-secondary" onClick={decrementAirTemp} style={{ margin: 0, width: 38, height: 38, marginRight: 5 }}><FontAwesomeIcon icon={faMinus} /></button>
                <button className="btn btn-secondary" onClick={incrementAirTemp} style={{ margin: 0, width: isMobile ? 36.5 : 38, height: isMobile ? 36.5 : 38, marginRight: 5 }}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
            </div>

            <div className="d-flex align-items-center mb-2" style={{justifyContent:'flex-end'}}>
              <label className="form-label me-2" style={{fontSize:isMobile ? 15 : 18}}>잎 온도 (°C) = </label>
              <input
                type="number"
                value={leafTemp}
                onChange={(e) => setLeafTemp(parseFloat(e.target.value))}
                min="0.5"
                max="50"
                step="0.5"
                className="form-control text-center me-2"
                style={{width:  isMobile ? 70:100, fontWeight: 'bold', fontSize:isMobile ? 15 : 18}}
              />
              <div>
              <button className="btn btn-secondary" onClick={decrementLeafTemp} style={{ margin: 0, width: 38, height: 38, marginRight: 5 }}><FontAwesomeIcon icon={faMinus} /></button>
              <button className="btn btn-secondary" onClick={incrementLeafTemp} style={{ margin: 0, width: isMobile ? 36.5 : 38, height: isMobile ? 36.5 : 38, marginRight: 5 }}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
              
            </div>
            

            
            <div className="d-flex align-items-center mb-2" style={{display: 'flex', justifyContent:'center'}}>
            <label class="form-label me-2" style={{fontSize: isMobile ? 15 : 18, marginLeft:isMobile ? 8 : 22}}>VPD = </label>

              <input
                  type="number"
                  value={vpdValue.toFixed(2)} 
                  className="form-control text-center me-2"
                  style={{width: isMobile ? 70:100, fontWeight: 'bold', fontSize:isMobile ? 15:18}}
                  disabled
                />
              <sub style={{fontSize:"18px", lineHeight:'unset'}}>kPa</sub>
            </div>
           
          </div>

          {/* Text Below Calculator */}
          <div className="text-below-calculator" style={{
            position: 'relative',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#000',
            marginTop: '20px'
          }}>
            <table className="table table-striped table-bordered">
              <thead className="thead-light">
              <tr>
                  <th>습도의 영향 </th>
                  <th>VPD 범위</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span style={{color:'#a5a5a5'}}>■</span> 과습 위험 범위</td>
                  <td>0~0.4 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'purple'}}>■</span> 삽목, 정식직후</td>
                  <td>0.4~0.8 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'#6970ff'}}>■</span> 생육초기에 적합</td>
                  <td>0.8~1.0 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'green'}}>■</span> 생육중기에 적합</td>
                  <td>1.0~1.2 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'rgb(212, 212, 25)'}}>■</span> 생육후기에 적합</td>
                  <td>1.2~1.6 kPa</td>
                </tr>
                <tr>
                  <td><span style={{color:'rgb(133, 133, 133)'}}>■</span> 건조 위험 범위</td>
                  <td>1.6~2.0 kPa</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
